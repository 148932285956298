<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <!-- bouton export start -->
          <b-button
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>

          <b-button
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>
          <!-- bouton export end -->
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-1 row">
            <div class="col">
              <v-select
                placeholder="Filtrer par association"
                :options="associations"
                id="association"
                v-model="selectedAssociation"
                @input="filterValue"
                @change="checkAssociation"
                label="name"
                value="id"
              >
                <template #no-options="{}"> Aucune donnée </template>
              </v-select>
            </div>

            <div class="col">
              <v-select
                placeholder="Filtrer par compte"
                :options="associationComptes"
                id="association"
                v-model="selectedCompte"
                @input="filterValue"
                label="libelle"
                value="libelle"
                :disabled="disabledFiltreCompte"
              >
                <template #no-options="{}"> Aucune donnée </template>
              </v-select>
            </div>

            <!-- date start -->
            <div class="col">
              <b-form-datepicker
                v-model="valueStart"
                placeholder="Date de début"
                labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                labelNoDateSelected="Aucune date sélectionnée"
                labelResetButton="Annuler"
                reset-button
                :date-format-options="dateFormatOptions"
                locale="fr"
                @input="filterValue"
              ></b-form-datepicker>
              <span
                class="text-danger"
                style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
              >
                {{ errorValueStart }}
              </span>
            </div>

            <div class="col">
              <b-form-datepicker
                v-model="valueEnd"
                placeholder="Date de fin"
                labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                labelNoDateSelected="Aucune date sélectionnée"
                labelResetButton="Annuler"
                reset-button
                :date-format-options="dateFormatOptions"
                locale="fr"
                @input="filterValue"
              ></b-form-datepicker>
            </div>
            <!-- date end -->

            <!-- select statut start -->
            <b-form-select
              v-model="selectedStatut"
              :options="statut_options"
              class="col"
              @input="filterValue"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- statuts --</b-form-select-option
                >
              </template>
            </b-form-select>
            <!-- select statut end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="ExcelManifestes"
            id="my-table"
            responsive
            :fields="headers"
            :items="manifestes"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            @filtered="onFiltered"
            show-empty
            class="border-bottom"
          >
            <template #cell(created_at)="data">
              <span>
                {{ data.item.created_at ? data.item.created_at : "----" }}
              </span>
            </template>
            <template #cell(association)="data">
              <a
                class="hoverSuccess"
                @click="showAssociation(data.item.association)"
              >
                {{
                  data.item.association ? data.item.association.name : "----"
                }}
              </a>
            </template>
            <template #cell(compte)="data">
              <a class="hoverSuccess" @click="showCompte(data.item.compte)">
                {{ data.item.compte ? data.item.compte.libelle : "----" }}
              </a>
            </template>

            <template #cell(acheteurassocie)="data">
              <span
                v-if="
                  data.item.acheteurassocie &&
                  data.item.acheteurassocie.type == 'acheteur'
                "
              >
                <span><u>Acheteur:</u></span>
                {{ data.item.acheteurassocie.name }}
              </span>
              <span
                v-else-if="
                  data.item.acheteurassocie &&
                  data.item.acheteurassocie.type == 'associe'
                "
              >
                <span><u>Associé:</u></span>
                {{ data.item.acheteurassocie.name }}
              </span>
              <span v-else> ---- </span>
            </template>

            <template #cell(navire_chargeur)="data">
              <span><u>NAVIRE:</u></span>
              {{ data.item.navire ? data.item.navire.name : "----" }}
              <br /><br />
              <span><u>CHARGEUR:</u></span>
              {{ data.item.chargeur ? data.item.chargeur.name : "----" }}
            </template>

            <template #cell(date_arrivee)="data">
              <span>
                {{ data.item.date_arrivee ? data.item.date_arrivee : "----" }}
              </span>
            </template>

            <template #cell(nbr_vehicules)="data">
              <span>
                {{ data.item.nbr_vehicules ? data.item.nbr_vehicules : 0 }}
              </span>
            </template>

            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.item.statut == 'Receptionné' ? 'primary' : 'warning'
                "
                >{{ data.item.statut }}</b-badge
              >
            </template>
            <template #cell(prix_revient)="data">
              {{ $thousandSeparator(data.item.prix_revient) }}
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div class="row">
                <div>
                  <b-button
                    :id="'button-1' + data.item.id"
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="detailsManifeste(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-1' + data.item.id"
                    title="Details"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                  <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                    </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>

            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          id="modal-editManifeste"
          v-model="modalEditManifeste"
          title="Receptionner manifeste"
          @hidden="resetModalEditManifeste"
          centered
          hide-footer
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="editForm"
            @submit.stop.prevent="handleSubmit"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="date_arrivee"
                rules="required"
              >
                <b-form-datepicker
                  v-model="editedDate.date_arrivee"
                  placeholder="Date d'arrivée"
                  labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                  labelNoDateSelected="Aucune date sélectionnée"
                  labelResetButton="Annuler"
                  reset-button
                  :date-format-options="dateFormatOptions"
                  locale="fr"
                  :max="maxDate"
                  @input="resetInputDate()"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsDateArrivalModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalEditManifeste = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonReceptionner"
                variant="primary"
                class="mt-2"
                @click="handleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal edit end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BBadge,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconArrowClockwise,
  BIconCircleFill,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BIconArrowClockwise,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BBadge,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      disabledFiltreCompte: true,
      etatButtonReceptionner: true,
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      statut_options: [
        { text: "Non receptionné", value: "Non receptionné" },
        { text: "Receptionné", value: "Receptionné" },
      ],
      associations: [],
      comptes: [],
      valueStart: null,
      valueEnd: null,
      errorValueStart: "",
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      lastSelectedAssociation: "",
      selectedAssociation: null,
      selectedCompte: null,
      selectedStatut: null,
      modalEditManifeste: false,
      perPage: 10,
      allRows: 0,
      currentPage: 1,
      filter: "",
      pageOptions: [],
      headers: [
        {
          key: "numero_manif",
          label: "Numéro",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Enregistrement",
          sortable: true,
        },
        {
          key: "association",
          label: "association",
          sortable: true,
        },
        {
          key: "compte",
          label: "compte",
          sortable: true,
        },
        {
          key: "navire_chargeur",
          label: "Navire & Chargeur",
          sortable: true,
        },
        {
          key: "date_arrivee",
          label: "Date arrivée",
          sortable: true,
          class: "text-center",
        },
        {
          key: "prix_revient",
          label: "P.revient",
          sortable: true,
        },
        {
          key: "nbr_vehicules",
          label: "Nbr Véhicule",
          sortable: true,
          class: "text-center",
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
      manifestesList: [],
      manifestes: [],
      associationComptes: [],
      ExcelManifestes: null,
      nameState: null,
      editedDate: {
        id: "",
        date_arrivee: "",
      },
      errorsDateArrivalModal: null,
    };
  },
  computed: {
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
      return maxDate;
    },

    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },
  },
  created() {
    this.getManifestes();
  },
  mounted() {
    this.getAssociationList();
    this.getCompteList();
  },
  methods: {
    showAssociation(item) {
      window.sessionStorage.setItem("association_id", item.id);
      localStorage.setItem("association_name", item.name);
      this.$router.push({
        name: "associations-show",
        params: {
          id: item.id,
          association_id: item.id,
          association_name: item.name,
        },
      });
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
        totalRows()
      },

    showCompte(item) {
      if (item) {
        window.sessionStorage.setItem("compte_id", item.id);
        this.$router.push({
          name: "compte-details",
        });
      } else {
        this.showToast(
          "Aucun compte n'est enregistré avec ce manifeste",
          "danger"
        );
      }
    },

    filterValue(val) {
      if (val) {
        this.disabledFiltreCompte = false;
      } else {
        this.disabledFiltreCompte = true;
      }

      this.filter = " ";
    },
    filterFunction(row, DateFilter) {
      if (this.selectedAssociation == null) {
        this.associationComptes = [];
        this.selectedCompte = null;
      } else {
        this.checkAssociation();
      }
      var start = null;
      var end = null;
      if (this.valueStart) {
        var parts = this.valueStart.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      if (this.valueEnd) {
        var parts = this.valueEnd.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      var value = new Date(this.toDate(row.created_at));
      if (
        (this.selectedStatut != null && this.selectedStatut != row.statut) ||
        (this.selectedAssociation &&
          this.selectedAssociation.name != row.association.name) ||
        (this.selectedCompte != null && this.selectedCompte.id != row.compte_id)
      ) {
        return false;
      } else {
        return this.dateFilter(start, end, value);
      }
    },
    async checkAssociation() {
      if (this.lastSelectedAssociation != this.selectedAssociation.name) {
        this.selectedCompte = null;
      }
      this.lastSelectedAssociation = this.selectedAssociation.name;
      let associes_acheteurList = this.selectedAssociation.acheteurs_associes;

      this.associationComptes = this.comptes.filter((compte) => {
        if (
          compte.author_type === "associe" &&
          compte.author_association === this.selectedAssociation.id
        ) {
          return true;
        }
      });
      associes_acheteurList.filter((element) => {
        if (element.type === "acheteur") {
          this.associationComptes.push(element.compte[0]);
        }
      });
    },
    dateFilter(start, end, value) {
      this.filter = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },
    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },
    getManifestes() {
      this.$http
        .get("/archive/manifestes")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          this.manifestesList = res.data.data.reverse();
          this.manifestes = res.data.data.reverse();

          this.manifestes.forEach((element) => {
            element.status == 0
              ? (element.statut = "Non receptionné")
              : (element.statut = "Receptionné");
          });

          if (this.manifestes.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }


          this.allRows = this.manifestes.length

        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    getAssociationList() {
      this.$http
        .get("/associations")
        .then((res) => {
          this.associations = res.data.data;
        })
        .catch((e) => {
          console.log("erreur associationList:", e.response.data.message);
        });
    },
    getCompteList() {
      this.$http
        .get("/comptes")
        .then((res) => {
          this.comptes = res.data.data.filter((compte) => {
            if (
              compte.author_type === "associe" ||
              compte.author_type === "acheteur"
            ) {
              return true;
            }
          });
        })
        .catch((e) => {
          console.log("erreur compteList:", e.response.data.message);
        });
    },

    // add date d'arrivée start
    editManifeste(item) {
      this.editedDate.id = item.id;
      this.modalEditManifeste = true;
    },

    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      if (!this.editedDate.date_arrivee) {
        this.errorsDateArrivalModal = "Veuillez sélectionner une date !";
      } else {
        this.etatButtonReceptionner = false;

        this.$http
          .put("/receptionner-manifeste/" + this.editedDate.id, this.editedDate)
          .then((result) => {
            if (result.data.success) {
              this.etatButtonReceptionner = true;
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditManifeste = false;
              this.showToast("Manifeste receptionné avec succès", "success");
              this.getManifestes();
            } else {
              this.etatButtonReceptionner = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonReceptionner = true;
            console.log("erreur d'ajout date:" + e);
            var err = e.response.data.errors;

            if (err) {
              if (err.date_arrivee) {
                this.errorsDateArrivalModal = err.date_arrivee[0];
              }
            } else if (e.response.status == 404) {
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditManifeste = false;
              this.showToast("Manifeste receptionné avec succès", "success");
              this.getManifestes();
            }
          });
      }
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },

    resetModalEditManifeste() {
      this.editedDate.date_Arrival = "";
      this.nameState = null;
      this.resetInputDate();
    },

    resetInputDate() {
      if (this.errorsDateArrivalModal) {
        this.errorsDateArrivalModal = null;
      }
    },
    // add date d'arrivée end

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    addsManifeste() {
      this.$router.push({
        name: "manifesteAdd",
      });
    },

    detailsManifeste(item) {
      window.sessionStorage.setItem("manifeste_data", JSON.stringify(item));

      this.$router.push({
        name: "manifesteDetails",
      });
    },

    deleteManifeste(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des manifestes ?",
          {
            title: "Supprimer une manifeste",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/manifestes/" + item.id, item)
              .then((result) => {
                if (result.data.success) {
                  this.showToast(
                    "Suppression effectuée avec succès",
                    "success"
                  );
                  this.getManifestes();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelManifestes.forEach((element) => {
        obj = {
          NUMERO: element.numero_manif,
          "DATE ENREGISTREMENT": element.created_at
            ? element.created_at
            : "----",
          ASSOCIATION: element.association ? element.association.name : "----",
          COMPTE: element.compte ? element.compte.libelle : "-----",
          NAVIRE: element.navire ? element.navire.name : "-----",
          CHARGEUR: element.chargeur ? element.chargeur.name : "-----",
          'DATE D"ARRIVEE': element.date_arrivee
            ? element.date_arrivee
            : "----",
          "NBR VEHICULE": element.nbr_vehicules ? element.nbr_vehicules : 0,
          // "TOTAL ACHAT":
          //   this.$thousandSeparator(element.prix_achat) +
          //   " " +
          //   element.dev_achat,
          // "TOTAL TRANSPORT":
          //   this.$thousandSeparator(element.prix_transport) +
          //   " " +
          //   element.dev_transport,
          // "TOTAL FRAIS":
          //   this.$thousandSeparator(element.frais) + " " + element.dev_frais,
          // "TOTAL CARTE IMPORTATEUR":
          //   this.$thousandSeparator(element.prix_carte_imp) +
          //   " " +
          //   element.dev_carte_imp,
          // DEPENSES: this.$thousandSeparator(element.total_depenses),
          "PRIX DE REVIENT": this.$thousandSeparator(element.prix_revient),
          // "STATUT": element.statut,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des manifestes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.manifestes.forEach((element) => {
        obj = {
          NUMERO: element.numero_manif,
          "DATE ENREGISTREMENT": element.created_at
            ? element.created_at
            : "----",
          ASSOCIATION: element.association ? element.association.name : "----",
          COMPTE: element.compte ? element.compte.libelle : "-----",
          NAVIRE: element.navire ? element.navire.name : "-----",
          CHARGEUR: element.chargeur ? element.chargeur.name : "-----",
          'DATE D"ARRIVEE': element.date_arrivee
            ? element.date_arrivee
            : "----",
          "NBR VEHICULE": element.nbr_vehicules ? element.nbr_vehicules : 0,
          // "TOTAL ACHAT":
          //   this.$thousandSeparator(element.prix_achat) +
          //   " " +
          //   element.dev_achat,
          // "TOTAL TRANSPORT":
          //   this.$thousandSeparator(element.prix_transport) +
          //   " " +
          //   element.dev_transport,
          // "TOTAL FRAIS":
          //   this.$thousandSeparator(element.frais) + " " + element.dev_frais,
          // "TOTAL CARTE IMPORTATEUR":
          //   this.$thousandSeparator(element.prix_carte_imp) +
          //   " " +
          //   element.dev_carte_imp,
          // DEPENSES: this.$thousandSeparator(element.total_depenses),
          "PRIX DE REVIENT": this.$thousandSeparator(element.prix_revient),
          // "STATUT": element.statut,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des manifestes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

// .bgcolor-button button{
// 	background-color: #27295c !important;
// }

.hoverSuccess:hover {
  // color: blue;
  // background-color: #ffff00;
  color: #2abe79 !important;
}
</style>
